<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        icon="el-icon-plus"
        type="primary"
        size="mini"
        @click="addRole()"
      >
        {{ $t("roleManager.addRole") }}
      </el-button>
    </div>
    <el-table
      v-loading="loading"
      :data="roleList"
      element-loading-text="Loading"
      border
      fit
      highlight-current-row
      stripe
    >
      <el-table-column
        type="index"
        align="center"
        :label="$t('commons.index')"
        width="95"
      >
      </el-table-column>
      <el-table-column
        prop="roleName"
        :label="$t('roleManager.name')"
        width="300"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="roleNameDesc" :label="$t('roleManager.desc')">
        <template slot-scope="scope">
          <template v-if="scope.row.edit">
            <el-input
              v-model="scope.row.roleNameDesc"
              maxlength="33"
              :show-word-limit="true"
              size="small"
              clearable
              :placeholder="$t('commons.pleaseInput')"
            />
          </template>
          <span v-else>{{ scope.row.roleNameDesc }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        :label="$t('commons.actions')"
        width="160"
      >
        <template slot-scope="scope">
          <template v-if="scope.row.roleName != 'Default'">
            <template v-if="scope.row.edit">
              <div class="ruge-buttons">
                <el-button type="text" @click="updateRole(scope.row)">{{
                  $t("commons.save")
                }}</el-button>
                <el-button type="text" @click="cancelEditRole(scope.row)">{{
                  $t("commons.cancel")
                }}</el-button>
              </div>
            </template>
            <template v-else>
              <div class="ruge-buttons">
                <el-button type="text" @click="allotPermission(scope.row)">{{
                  $t("permission.grantPermission")
                }}</el-button>
                <el-button type="text" @click="editRole(scope.row)">{{
                  $t("commons.edit")
                }}</el-button>
                <el-button
                  type="text"
                  style="color: #ff0000"
                  @click="deleteRole(scope.row)"
                  >{{ $t("commons.delete") }}</el-button
                >
              </div>
            </template>
          </template>
          <template v-else>
            <div class="ruge-buttons">
              <el-button type="text" @click="allotPermission(scope.row)">{{
                $t("permission.grantPermission")
              }}</el-button>
            </div>
          </template>
        </template>
      </el-table-column>
    </el-table>
    <!-- 增加角色 -->
    <el-dialog :visible.sync="editMode" width="30%" :before-close="quitAddRole">
      <template slot="title">
        <title-icon />{{ $t("roleManager.addRole") }}
      </template>
      <el-form
        label-position="top"
        :model="form"
        ref="roleForm"
        :rules="roleRule"
      >
        <el-form-item :label="$t('roleManager.name')" prop="roleName">
          <el-input
            v-model="form.roleName"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('roleManager.desc')" prop="roleNameDesc">
          <el-input
            v-model="form.roleNameDesc"
            maxlength="33"
            :show-word-limit="true"
            autocomplete="off"
            clearable
            :placeholder="$t('commons.pleaseInput')"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="quitAddRole()">{{ $t("commons.cancel") }}</el-button>
        <el-button type="primary" @click="saveRole()">{{
          $t("commons.save")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
let manager = require("@/api/ruge/security/role");

export default {
  name: "RoleTenantManager",
  data() {
    return {
      loading: true,
      roleList: [],
      role: {
        listQuery: {
          current: 1,
          rowCount: 100,
          tenantId: null,
        },
      },
      editMode: false,
      currentRow: {
        roleName: "",
        roleCode: "",
        tenantId: null,
        roleId: null,
      },
      form: {
        tenantId: null,
        roleName: "",
        roleNameDesc: "",
      },
      roleRule: {
        roleName: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
        tenantId: [
          {
            required: true,
            trigger: ["blur", "change"],
            message: this.$t("validate.required"),
          },
        ],
      },
    };
  },
  created() {
    this.getRolePage();
  },
  computed: {
    roleTab() {
      return defineTab.roleTab;
    },
  },
  methods: {
    getRolePage() {
      this.loading = true;
      manager
        ._tenant_getRolePage(this.role.listQuery)
        .then((response) => {
          this.roleList = response.rows.map((v) => {
            this.setOrgRole(v);
            return v;
          });
          this.role.total = response.total;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.log(`查询失败，原因 => ${error}`);
        });
    },
    editRole(row) {
      this.$set(row, "edit", true);
    },
    // 删除用户
    deleteRole(row) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          // 调用服务
          manager
            ._tenant_deleteRole({ tenantId: row.tenantId, roleId: row.roleId })
            .then(() => {
              // 成功提示
              this.$message({
                type: "success",
                message: this.$t("message.deleteSuccess"),
              });
              // 刷新列表
              this.getRolePage();
            })
            .catch(() => {});
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    // 更新角色
    updateRole(row) {
      manager
        ._tenant_updateRole(row)
        .then(() => {
          row.edit = false;
          this.setOrgRole(row);
          this.$message({
            type: "success",
            message: this.$t("message.saveSuccess"),
          });
        })
        .catch((error) => {
          console.log(`保存失败，原因 => ${error}`);
        });
    },
    cancelEditRole(row) {
      this.resetOrgRole(row);
      row.edit = false;
    },
    setOrgRole(row) {
      row.orgRoleCode = row.roleCode;
      row.orgRoleName = row.roleName;
      row.orgRoleNameDesc = row.roleNameDesc;
    },
    resetOrgRole(row) {
      row.roleCode = row.orgRoleCode;
      row.roleName = row.orgRoleName;
      row.roleNameDesc = row.orgRoleNameDesc;
    },
    // 添加角色
    addRole() {
      this.editMode = true;
    },
    //退出新增角色
    quitAddRole() {
      this.$refs.roleForm.resetFields();
      this.editMode = false;
    },
    //保存角色
    saveRole() {
      this.$refs.roleForm.validate((valid) => {
        if (valid) {
          manager
            ._tenant_saveRole(this.form)
            .then(() => {
              this.editMode = false;
              this.form.tenantId = null;
              this.form.roleName = null;
              this.form.roleNameDesc = null;
              this.$message({
                type: "success",
                message: this.$t("message.saveSuccess"),
              });
              this.getRolePage();
            })
            .catch(() => {});
        }
      });
    },
    // 分配权限
    allotPermission(row) {
      this.$router.push({
        path: "/tenant/permission/role/detail",
        query: {
          roleName: row.roleName,
          roleCode: row.roleCode,
          roleId: row.roleId,
          tenantId: row.tenantId,
        },
      });
    },
  },
};
</script>

