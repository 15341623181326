var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c(
            "el-button",
            {
              attrs: { icon: "el-icon-plus", type: "primary", size: "mini" },
              on: {
                click: function ($event) {
                  return _vm.addRole()
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("roleManager.addRole")) + "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: {
            data: _vm.roleList,
            "element-loading-text": "Loading",
            border: "",
            fit: "",
            "highlight-current-row": "",
            stripe: "",
          },
        },
        [
          _c("el-table-column", {
            attrs: {
              type: "index",
              align: "center",
              label: _vm.$t("commons.index"),
              width: "95",
            },
          }),
          _c("el-table-column", {
            attrs: {
              prop: "roleName",
              label: _vm.$t("roleManager.name"),
              width: "300",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.roleName))])]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: { prop: "roleNameDesc", label: _vm.$t("roleManager.desc") },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.edit
                      ? [
                          _c("el-input", {
                            attrs: {
                              maxlength: "33",
                              "show-word-limit": true,
                              size: "small",
                              clearable: "",
                              placeholder: _vm.$t("commons.pleaseInput"),
                            },
                            model: {
                              value: scope.row.roleNameDesc,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "roleNameDesc", $$v)
                              },
                              expression: "scope.row.roleNameDesc",
                            },
                          }),
                        ]
                      : _c("span", [_vm._v(_vm._s(scope.row.roleNameDesc))]),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              label: _vm.$t("commons.actions"),
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.roleName != "Default"
                      ? [
                          scope.row.edit
                            ? [
                                _c(
                                  "div",
                                  { staticClass: "ruge-buttons" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateRole(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("commons.save")))]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelEditRole(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : [
                                _c(
                                  "div",
                                  { staticClass: "ruge-buttons" },
                                  [
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.allotPermission(
                                              scope.row
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("permission.grantPermission")
                                          )
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.editRole(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("commons.edit")))]
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: { color: "#ff0000" },
                                        attrs: { type: "text" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteRole(scope.row)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(_vm.$t("commons.delete")))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                        ]
                      : [
                          _c(
                            "div",
                            { staticClass: "ruge-buttons" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.allotPermission(scope.row)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.$t("permission.grantPermission"))
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.editMode,
            width: "30%",
            "before-close": _vm.quitAddRole,
          },
          on: {
            "update:visible": function ($event) {
              _vm.editMode = $event
            },
          },
        },
        [
          _c(
            "template",
            { slot: "title" },
            [
              _c("title-icon"),
              _vm._v(_vm._s(_vm.$t("roleManager.addRole")) + "\n    "),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "roleForm",
              attrs: {
                "label-position": "top",
                model: _vm.form,
                rules: _vm.roleRule,
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("roleManager.name"),
                    prop: "roleName",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "33",
                      "show-word-limit": true,
                      autocomplete: "off",
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.form.roleName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleName", $$v)
                      },
                      expression: "form.roleName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: _vm.$t("roleManager.desc"),
                    prop: "roleNameDesc",
                  },
                },
                [
                  _c("el-input", {
                    attrs: {
                      maxlength: "33",
                      "show-word-limit": true,
                      autocomplete: "off",
                      clearable: "",
                      placeholder: _vm.$t("commons.pleaseInput"),
                    },
                    model: {
                      value: _vm.form.roleNameDesc,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "roleNameDesc", $$v)
                      },
                      expression: "form.roleNameDesc",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      return _vm.quitAddRole()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.cancel")))]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.saveRole()
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.$t("commons.save")))]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }